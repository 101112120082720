import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Register = () => {
  return (
    <section className="about-register">
      <div className="container-fluid">
        <h2>
          Zarejestruj się
          <br />i zainwestuj już dziś!
        </h2>
        <Link to="/projekt-15/" className="button button--circle">
          więcej
        </Link>
      </div>
    </section>
  )
}

export default Register
