import "./styles.scss"

import React from "react"

const GreenArea = () => {
  return (
    <section className="about-green-area">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2>Zieleń jest najważniejsza</h2>
            <p>
              Naszym zadaniem jest takżę tworzenie osiedli mieszkaniowych o
              wysokim standardzie oraz obiektów handlowo usługowych dostępnych
              dla wszystkich. Stawiamy na jak najbardziej ekologiczne
              rozwiązania i walczymy o zachowanie jak największych terenów
              zieleni w obrębie inwestycji.
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={
                require("assets/images/about/about-green-area-image.png")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GreenArea
