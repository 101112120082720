import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Mission, GreenArea, Register } from "page_components/about"

const About = () => {
  const title = "O Trust"

  return (
    <Layout>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Mission />
      <GreenArea />
      <Register />
    </Layout>
  )
}

export default About
